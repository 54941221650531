<template>
  <v-container class="p-4 px-1">
    <loading-flux :value="loading"></loading-flux>
    <section class="d-flex flex-column flex-sm-row justify-content-between">
      <h2 class="ml-1 mt-2 mb-2">
        {{ title }}
      </h2>
      <v-breadcrumbs :items="breadcrumb"></v-breadcrumbs>
    </section>
    <v-row align-content="center" align-md="center">
      <v-col cols="12" md="4" sm="12">
        <v-autocomplete
          v-model="filters.warehouse_filter"
          clearable
          :items="
            mobileWarehousesStock
              ? mobileWarehouses
              : warehouses.items
              ? warehouses.items
              : []
          "
          @input="getReportStockData(1)"
          class=""
          hide-details
          item-value="code"
          :item-text="mobileWarehousesStock ? 'name_code' : 'name'"
          no-data-text="Sin bodegas"
          label="Seleccionar bodega"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="4" sm="12">
        <v-text-field
          label="Busqueda de producto"
          v-model="filters.filter"
          @input="getReportStockData(1)"
          clearable
          hide-details
          append-icon="mdi-magnify"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4" sm="12" class="">
        <v-btn
          class="float-right mt-1"
          color="primary text-white"
          small
          @click="downloadExcelReportStock"
        >
          <v-icon small class="mr-1">
            mdi-export
          </v-icon>
          Exportar
        </v-btn>
        <v-btn
          class="float-right mt-1 mx-3"
          color="success text-white"
          small
          @click="updateReportStock"
        >
          <v-icon small class="mr-1">
            mdi-update
          </v-icon>
          Sincronizar Datos Reporte
        </v-btn>
      </v-col>
      <v-col cols="12" lg="3" md="3" sm="12" class="">
        <v-checkbox
          v-model="filters.only_with_stock"
          @click="getReportStockData(1)"
        >
          <template v-slot:label>
            <h6 class="mt-2 mx-1 mb-0">
              Solo Materiales con Stock
            </h6>
          </template>
        </v-checkbox>
      </v-col>
    </v-row>
    <v-data-table
      :key="keyTable"
      :headers="tableHeaders"
      :items="wrappedReportStock"
      :page.sync="filters.page"
      :items-per-page="filters.items !== 'Todos' ? filters.items : 100"
      item-key="id"
      :show-select="false"
      :loading="loadingMaterials"
      class="elevation-3 mt-3 pt-2"
      loading-text="Cargando reporte"
      hide-default-footer
      no-data-text="Sin productos actualmente"
    >
      <template v-slot:item.Solicitado="{ item }">
        <p class="my-0 text-table text-right">
          {{ item.Solicitado.toLocaleString("es-CL", {}) }}
        </p>
      </template>
      <template v-slot:item.Comprometido="{ item }">
        <p class="my-0 text-table text-right">
          {{ item.Comprometido.toLocaleString("es-CL", {}) }}
        </p>
      </template>
      <template v-slot:item.Disponible="{ item }">
        <p class="my-0 text-table text-right">
          {{
            item.Disponible ? item.Disponible.toLocaleString("es-CL", {}) : "-"
          }}
        </p>
      </template>
      <template v-slot:item.stock="{ item }">
        <p class="my-0 text-table text-right">
          {{ item.stock.toLocaleString("es-CL", {}) }}
        </p>
      </template>
      <template v-slot:item.Total="{ item }">
        <p class="my-0 text-table text-right">
          {{ item.Total.toLocaleString("es-CL", {}) }}
        </p>
      </template>
      <template v-slot:item.Precio="{ item }">
        <p class="my-0 text-table text-right">
          {{
            item.Precio.toLocaleString("es-CL", {
              style: "currency",
              currency: "CLP",
            })
          }}
        </p>
      </template>
      <template v-slot:item.Código="{ item }">
        <p class="my-0 text-table">
          {{ item.Código }}
        </p>
      </template>
      <template v-slot:item.Almacén="{ item }">
        <p class="my-0 text-table">
          {{ item.Almacén }}
        </p>
      </template>
      <template v-slot:item.Descripción="{ item }">
        <p class="my-0 text-table text-left">
          {{ item.Descripción }}
        </p>
      </template>
    </v-data-table>
    <div class="d-flex justify-content-end pt-3">
      <v-pagination
        :total-visible="8"
        v-model="filters.page"
        :length="reportStock.total_pages ? reportStock.total_pages : 1"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import swal2 from "sweetalert2";
import moment from "moment";
export default {
  props: {
    title: {
      type: String,
      default: "Abastecimiento - Reporte de Stock",
    },
    mobileWarehousesStock: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.getReportStockData();
    if (this.mobileWarehousesStock) {
      return this.getMobileWarehouses();
    }
    this.getWarehousesData();
  },
  data() {
    return {
      loading: false,
      loadingMaterials: false,
      debounce: null,
      debounceTime: 500,
      debounceWarehouses: null,
      debounceWarehousesTime: 500,

      keyTable: null,
      filters: {
        page: 1,
        filter: "",
        items: 20,
        warehouse_filter: null,
        only_with_stock: false,
      },
      tableHeaders: [
        {
          text: "Bodega",
          align: "center",
          sortable: false,
          value: "Almacén",
        },
        {
          text: "Código",
          align: "center",
          sortable: false,
          value: "Código",
        },
        {
          text: "Descripción",
          align: "center",
          sortable: false,
          value: "Descripción",
        },
        {
          text: "Stock",
          align: "center",
          sortable: false,
          value: "stock",
          class: "text-right",
        },
        {
          text: "Precio",
          align: "center",
          sortable: false,
          value: "Precio",
        },
        {
          text: "Solicitado",
          align: "center",
          sortable: false,
          value: "Solicitado",
        },
        {
          text: "Comprometido",
          align: "center",
          sortable: false,
          value: "Comprometido",
        },
        {
          text: "Disponible",
          align: "center",
          sortable: false,
          value: "Disponible",
        },
        {
          text: "Total",
          align: "center",
          sortable: false,
          value: "Total",
        },
      ],
      breadcrumb: [
        {
          text: "Abastecimiento",
          disabled: false,
          href: "/supplying",
        },
        {
          text: "Reporte de Stock",
          disabled: true,
          href: "/supplying/reports/stock",
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      getReportStock: "supplying/getReportStock",
      sendDownloadExcelReportStock: "supplying/downloadExcelReportStock",
      sendUpdateReportStock: "supplying/updateReportStock",
      getWarehouses: "requestMaterialsSap/getWarehouses",
      getMobileWarehouses: "mobileWarehouse/getMobileWarehouses",
    }),
    async getReportStockData(page = 1) {
      let self = this;
      clearTimeout(self.debounce);
      self.debounce = setTimeout(async function() {
        self.loadingMaterials = true;
        await self.getReportStock({
          pages: page - 1,
          items: self.filters.items,
          material_filter: self.filters.filter
            ? self.filters.filter
            : undefined,
          warehouse_filter: self.filters.warehouse_filter
            ? self.filters.warehouse_filter
            : undefined,
          only_with_stock: self.filters.only_with_stock,
          only_movil: self.mobileWarehousesStock ? true : undefined,
        });
        self.loadingMaterials = false;
      }, self.debounceTime);
    },
    async getWarehousesData(search = null) {
      clearTimeout(this.debounceWarehouses);
      let self = this;
      this.debounceWarehouses = setTimeout(async function() {
        await self.getWarehouses({
          filter: search ? search : undefined,
          items: 60,
        });
      }, self.debounceWarehousesTime);
    },
    async updateReportStock() {
      this.loading = true;
      const response = await this.sendUpdateReportStock();
      if (response.status == 200) {
        await this.getReportStockData();
        swal2.fire({
          icon: "success",
          title: "Excelente",
          text: "Reporte actualizado correctamente",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 6000,
          timerProgressBar: true,
        });
      }
      this.loading = false;
    },
    async downloadExcelReportStock() {
      this.loading = true;
      const response = await this.sendDownloadExcelReportStock({
        material_filter: this.filters.filter ? this.filters.filter : undefined,
        warehouse_filter: this.filters.warehouse_filter
          ? this.filters.warehouse_filter
          : undefined,
        only_with_stock: this.filters.only_with_stock,
        only_movil: this.mobileWarehousesStock ? true : undefined,
      });
      let fileURL = window.URL.createObjectURL(new Blob([response]));
      let fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute(
        "download",
        this.mobileWarehousesStock
          ? "reporte_stock_bodegasmoviles.xlsx"
          : this.filters.only_with_stock
          ? `reporte_solo_con_stock_abastecimiento_${moment().format(
              "YYYY-MM-DD"
            )}.xlsx`
          : `reporte_stock_abastecimiento_${moment().format("YYYY-MM-DD")}.xlsx`
      );
      document.body.appendChild(fileLink);
      fileLink.click();
      this.loading = false;
    },
  },
  computed: {
    ...mapGetters({
      reportStock: "supplying/reportStock",
      warehouses: "requestMaterialsSap/warehouses",
      mobileWarehouses: "mobileWarehouse/mobileWarehouses",
    }),
    wrappedReportStock() {
      if (this.reportStock.stock_report?.length) {
        return this.reportStock.stock_report.map((item) => {
          return {
            ...item,
            stock: item["En Stock"],
          };
        });
      }
      return [];
    },
    getWarehouseCentralName() {
      return this.warehouses.items
        ? this.warehouses.items.find(
            (item) => item.code == CODE_WAREHOUSE_CENTRAL_FLUXSOLAR
          ).name
        : "-";
    },
  },
  watch: {
    "filters.page": function(page) {
      this.getReportStockData(page);
    },
  },
};
</script>

<style></style>
